import React from "react";
import homePic from "../assets/us.jpg";
import ContributeButtons from "./ContributeButtons";

class Home extends React.Component {
  constructor(props) {
    var weddingDate = new Date("08/29/20");
    var todaysDate = new Date();
    var timeDiff = weddingDate.getTime() - todaysDate.getTime();
    var dateDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    super(props);

    this.state = { weddingDate: dateDiff, imgLoaded: false };
  }

  componentDidMount() {
    const img = new Image();
    img.src = homePic;
    img.onload = () => {
      this.setState({ imageLoaded: true });
    };
  }

  render() {
    const { imageLoaded } = this.state;
    return (
      <div>
        {imageLoaded ? (
          <div>
            <div className="AF-Img-Div">
              <img className="AF-Img" src={homePic} />
              <div className="AF-Img-Text">
                <p className="LText MainFont">Anastasia & Feliks</p>
                <p className="MText MainFont">August 29, 2020 • Brooklyn, NY</p>
                <p className="SText MainFont">
                  {this.state.weddingDate} days to go!
                </p>
                <br />
              </div>
            </div>
          </div>
        ) : (
          <p className="AF-Loading">Loading...</p>
        )}
      </div>
    );
  }
}

export default Home;
